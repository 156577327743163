import * as Sentry from '@sentry/react';

export const SentryFeedBack = Sentry.feedbackIntegration({
    // Additional SDK configuration goes in here, for example:
    isEmailRequired: true,
    triggerLabel: '',
    showBranding: false,
    useSentryUser: {
        name: 'fullName',
        email: 'email',
    },
    addScreenshotButtonLabel: 'Create Screenshot',
    autoInject: false,
});

export const initSentry = (dsn, { env } = {}) => {
    if (!dsn) {
        return;
    }

    Sentry.init({
        dsn,
        environment: env,

        // Send all error events
        sampleRate: 1,
        // Enables transaction traces and send only fix rate of them
        tracesSampleRate: 0.2,
        // Note: Needed so Sentry.httpClientIntegration() can properly collect headers and cookies
        sendDefaultPii: true,
        // Prevents tracking of replays for each event
        replaysSessionSampleRate: 0,
        // Enables tracking of replays for each error event
        replaysOnErrorSampleRate: 1,

        integrations: [
            // Enables video-like reproduction of user sessions
            Sentry.replayIntegration(),

            // Reports error events on failed HTTP requests
            Sentry.httpClientIntegration(),

            // Report error events on console logs filtered out by their level
            Sentry.captureConsoleIntegration(['error']),

            // Note: Temporary disabled until aligned with the rest of the issue reporting workflows
            // SentryFeedBack,
        ],
    });
};
